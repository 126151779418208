<template>
	<div class="ybd-view">
		<ucenterHeader ></ucenterHeader>
		<div class="container">
			<ucenterLeftNav></ucenterLeftNav>
			<div class="main">
				<div class="u-rightbar">
					<div class="module-title"><i></i><span>我的答题</span></div>
						<template v-if="list.length > 0">
							<el-table :data="list" class="form-table" :show-header="false" style="width: 100%">
								<el-table-column prop="title" label="标题"></el-table-column>
								<el-table-column prop="date_count" label="参与人数" width="200">
									<template slot-scope="scope">{{scope.row.date_count}}人参与</template>
								</el-table-column>
								<el-table-column prop="createTime" label="答题时间" width="200"></el-table-column>
							</el-table>
							<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange" :current-page="listParams.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="listParams.pageSize" layout="total, prev, pager, next, jumper" :total="count" prev-text="上一页" next-text="下一页" background></el-pagination>
						</template>
						<noData v-else text="暂时还没有答题哦！"></noData>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { get } from "/src/services/ajax_ucenter.js";
import ucenterHeader from "@/components/ucenterHeader.vue";
import ucenterLeftNav from "@/components/ucenterLeftNav.vue";
import noData from "@/components/noData";
export default {
	components: {
		ucenterHeader,
		ucenterLeftNav,
		noData,
	},
	data(){
		return {
			listParams:{
				pageNo:1,
				pageSize:20
			},
			count:1,
			list:[],
		}
	},
	created(){
		
	},
	computed: {
	},
	watch:{
	},
	mounted(){
		this.getList();
	},
	methods: {
		// 获取模板列表
		getList(){
			const _this = this;
			get('/formadmin/myAnswar.jhtml',_this.listParams).then(res => {
				if(res.code == 200){
					// _this.listParams.pageNo = res.data.pageNo;
					// _this.listParams.pageSize = res.data.pageSize;
					_this.count = res.data.count;
					_this.list = res.data.date;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		pageCurrentChange(parm){
			this.listParams.pageNo = parm;
			this.getList();
		},
		pageSizeChange(parm){
			this.listParams.pageSize = parm;
			this.getList();
		},
	}
};
</script>
<style lang="less" scoped>
.main {
	display: flex;
	flex-direction: column;
	padding: 20px;
	overflow-y: auto;
	.u-rightbar {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 20px 30px;
		background: #fff;
		.module-title{
			font-size: 16px;
			line-height: 36px;
			color:#333;
			margin-bottom: 10px;
			padding:0;
			border-bottom:none; 
			i{
				width: 4px;
				height:16px;
				background: var(--theme-color);
				margin-right: 10px;
				display: inline-block;
				vertical-align:-2px;
			}
		}
		.i-ico {
			width: 20px;
			height: 20px;
			cursor: pointer;
			display: inline-block;
			vertical-align: middle;
			background:url("../../../assets/images/icon_main.png") -25px -246px no-repeat;
		}
	}
	
	.form-table{
		border-top: 1px solid #EBEEF5;
	} 
	
	::v-deep .el-pagination{
		padding: 20px 10px 10px;
		font-weight: normal;
		text-align: right;
		.el-pagination__total,
		.el-pagination__sizes{
			float: left;
			.el-input{
				.el-input__inner{
					&:hover{
						border-color: var(--theme-color);
					}
				}
			}
		}
		.el-pager{
			li:not(.disabled){
				&:hover{
					color: var(--theme-color);
				}
			}
			li:not(.disabled).active{
				background-color: var(--theme-color);
				&:hover{
					color: #fff;
				}
			}
		}
		.btn-prev,
		.btn-next{
			font-size: 12px;
			padding: 0 5px;
		}
	}	
		
}
</style>